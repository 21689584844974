<template>
  <div class="offers">
    <div class="offer-single" :style="`--cardBgColor: ${ its.isDisable ? themeColor2.offersBgcolor : themeColor1.offersBgcolor}`"
     v-for="(its, idx) in offersList" :key="idx">
     
      <div class="header" :style="`--themeColor: ${ its.isDisable ? themeColor2.bgcolor : themeColor1.bgcolor}`">
        <span>Offer {{ idx + 1 }}</span>
      </div>

      <div class="container" v-if="its.name_tc">
        <div class="card">
          <div class="card-single">
            <div class="label">Item (TC)</div>
            <div class="value">{{ its.name_tc }}</div>
          </div>
          <div class="card-single">
            <div class="label">Item (EN)</div>
            <div class="value">{{ its.name_en }}</div>
          </div>
          <div class="card-single">
            <div class="label">Number of Stamps Costing</div>
            <div class="value">{{ its.stampCostingNumber }}</div>
          </div>
          <div class="card-single">
            <div class="label expand"> 
              <div class="t-c" @click="its.general_tc_select = !its.general_tc_select">
                <span>T&C (TC)</span>
                <img src="@/assets/images/expand.png" alt="">
              </div>
              <span class="description" v-if="its.general_tc_select">{{ its.general_tc }}</span>
            </div>
          </div>
          <div class="card-single">
            <div class="label expand">
              <div class="t-c" @click="its.general_en_select = !its.general_en_select">
                <span>T&C (EN)</span>
                <img src="@/assets/images/expand.png" alt="">
              </div>
              <span class="description" v-if="its.general_en_select">{{ its.general_en }}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="container" v-else>
        <div class="card-empty">
          No request
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { rewardDisableRequests, rewardEnableRequests, getRewardRedemptions } from '@/api/reward'

export default {
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  data() {
    return {
      offersList: [],
      themeColor1: {
        bgcolor: '#1B7379',
        offersBgcolor: '#FAFAFA'
      },
      themeColor2: {
        bgcolor: '#6D6D6D',
        offersBgcolor: '#F0F0EF'
      }
    }
  },
  methods: {
    isDisable(row) {
      rewardDisableRequests(row.id).then(() => {
        row.isDisable = !row.isDisable
      })
    },
    isEnable(row) {
      rewardEnableRequests(row.id).then(() => {
        row.isDisable = !row.isDisable
      })
    },
    toDetail(idx) {
      let detail = this.offersList[idx]
      detail.idx = idx
      this.$emit('tabOffersEdit', JSON.parse(JSON.stringify(detail)))
    },
    getData() {
      let eatery_id = this.$route.params.id
      
      getRewardRedemptions({eatery_id}).then(v => {
        console.log(v.data)
        let offers = [{}, {}]
        v.data.filter((its, index) => {
          its.name_tc = its.item.zhHant
          its.name_en = its.item.en
          its.general_tc = its.general.zhHant
          its.general_en = its.general.en
          its.general_tc_select = false
          its.general_en_select = false
          its.is_disable = its.is_disable ? true : false
          its.curIdx = index
          // if(its.status == 0) {
          //   if(its.type == 0) offers[0] = {}
          //   else if(its.type == 1) offers[1] = {}
          // } else {
          // }
          if(its.type == 0) offers[0] = its
          else if(its.type == 1) offers[1] = its
        })
        this.offersList = offers
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>  

<style scoped lang="scss">
.offers {
  width: 100%; 
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  .offer-single {
    width: 41.25rem;
    height: fit-content;
    border-radius: 1.25rem;
    background-color: var(--cardBgColor);
    margin: 0 1.25rem 1.25rem 0;

    .header {
      width: 100%;
      height: 4.25rem;
      border-radius: 1.25rem 1.25rem 0 0;
      background-color: var(--themeColor);
      padding: 0 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .container {
      width: 100%;
      padding: 1.875rem 1.25rem;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .card-empty {
        width: 100%;
        height: 53.125rem;
        border-radius: .9375rem;
        border: 1px solid #BCBCBC;
        padding: 1.25rem 1.5625rem 1.875rem 1.5625rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
        color: #000;
      }

      .card {
        width: 100%;
        border-radius: .9375rem;
        border: 1px solid #BCBCBC;
        padding: 1.25rem 1.5625rem 1.875rem 1.5625rem;
        box-sizing: border-box;

        .card-single {
          width: 100%;
          margin-top: 1.25rem;
          color: #000;
          font-size: 1.125rem;
          .label {
            font-weight: 700;
            margin-bottom: .625rem;
          }
          .expand {
            width: 100%;
            border-bottom: 1px solid #1B7379;
            padding-bottom: .625rem;
            .t-c {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: .625rem 0;
              box-sizing: border-box;
              
              img {
                width: 1rem;
                height: auto;
              }
            }
            .description {
              width: 100%;
              font-size: .875rem;
              font-weight: 400;
            }
          }
        }
      }

      .edit-btn {
        margin-top: 1.875rem;
        width: 26.25rem;
        height: 3rem;
        background-color: var(--themeColor);
        color: #fff;
        font-size: 1.125rem;
        font-weight: 700;
        border-radius: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
</style>
import http from '@/utils/http'

export function getRewardRedemptions(params){
  return http('/reward_redemptions', {params})
}

export function addRewardRedemptions(data){
  return http.post('/reward_redemptions', data)
}

export function getRewardRedemptionsDetail(id){
  return http(`/reward_redemptions/${id}`)
}

export function editRewardRedemptions(id, data){
  return http.put(`/reward_redemptions/${id}`, data)
}

export function rewardApproveRequests(id){
  return http.post(`/reward_redemptions/${id}/approve_requests`)
}

export function rewardRejectRequests(id){
  return http.post(`/reward_redemptions/${id}/reject_requests`)
}

export function rewardDisableRequests(id){
  return http.post(`/reward_redemptions/${id}/disable_requests`)
}

export function rewardEnableRequests(id){
  return http.post(`/reward_redemptions/${id}/enable_requests`)
}


<template>
  <div class="summary">
    <el-select v-model="group" clearable placeholder="All groups" @change="changeSelect" style="margin-top: 20px;">
      <el-option v-for="(item,index) in groups" :key="index" 
        :label="item.label" :value="item.value">
      </el-option>
    </el-select>

    <el-cascader v-if="group == 1" style="margin-left: 10px;" v-model="specificMonth" :options="specificMonthOptions"
    @change="handleChange" separator=" "></el-cascader>

    <div class="cp">
      <div class="tip-title" v-if="date">Statistics of {{ date }}:</div>
      <!-- <div class="item">
        <div class="label">Number of eatery branches:</div>
        <div class="value">{{ eateries.length }}</div>
      </div> -->
    </div> 

    <div class="sr">
      <div class="tip-title">Stamps</div>
      <div class="card">
        <div class="single">
          <div class="description">Disposable cutlery saved:</div>
          <span class="value">{{ itemData.stamps.disposableCutlerySaved }}</span>
        </div>

        <div class="single">
          <div class="description">Disposable containers saved:</div>
          <span class="value">{{ itemData.stamps.disposableContainersSaved }}</span>
        </div>

        <div class="single">
          <div class="description">Total no. of Plastic Free Action:</div>
          <span class="value">{{ itemData.stamps.totalNoOfPlasticFreeAction }}</span>
        </div>
        
        <div class="single">
          <div class="description">Carbon footprint reduced:</div>
          <span class="value">{{ itemData.stamps.carbonFootprintReduced }}</span>
        </div>

        <div class="single">
          <div class="description">Stamps awarded to members:</div>
          <span class="value">{{ itemData.stamps.stampsAwardedToMembers }}</span>
        </div>
      </div>
    </div>

    <div class="cs">
      <div class="tip-title">Reward</div>
      <div class="card">
        <div class="single" v-for="(its, idx) in itemData.rewards" :key="idx">
          <div class="description">Reward {{ idx + 1 }} redeemed:</div>
          <span class="value">{{ its.redeemed }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eateriesSummary } from '@/api/eatery'

export default {
  props: {
    eateries: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let today = new Date().format('yyyy/MM/dd')

    return {
      date: '',
      specificMonth: [],
      yearMonth: [],
      specificMonthOptions: [],
      monthEn: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

      group: 0,
      groups: [
        {label: 'Today', value: 0},
        {label: 'Specific Month', value: 1},
        {label: 'Entire Campaign', value: 2},
      ],
      form: {
        start_date: today + ' 00:00:00',
        end_date: today + ' 00:00:00',
        eatery_id: this.$route.params.id,
      },
      index: null,
      itemData: {
        "stamps": {},
        "rewards": []
      }
    }
  },
  computed: {
    today: function() {
      return new Date().format('YYYY/MM/dd')
    }
  },
  methods: {
    changeSelect(v) {
      if(v == 0) {
        this.getToday()
        this.date = this.today
      } else if(v == 1) {
        let [init] = this.specificMonthOptions
        this.specificMonth = [init.value, init.children[0].value]

        this.date = init.children[0].value + ' ' + init.value
        this.getSpecificMonth(init.value, 1)
      } else if(v == 2){
        this.form = {
          start_date: ``,
          end_date: ``,
          eatery_id: this.$route.params.id,
        }
        this.date = ''
      }

      this.getData()
    },
    handleChange(v) {
      let [year, month] = v
      this.date = month + ' ' + year
      this.getSpecificMonth(year, this.monthEn.indexOf(month) + 1)
    },
    getSpecificMonth(year, month) {
      let dayTotal = new Date(year, month, 0).getDate()
      this.form = {
        start_date: `${year}/${month}/01 00:00:00`,
        end_date: `${year}/${month}/${dayTotal} 23:59:59`,
        eatery_id: this.$route.params.id,
      }

      this.getData()
    },
    getToday() {
      let today = new Date().format('yyyy/MM/dd')
      this.form = {
        start_date: today + ' 00:00:00',
        end_date: today + ' 23:59:59',
        eatery_id: this.$route.params.id,
      }
    },
    getData() {
      let form = {...this.form}
      eateriesSummary(form).then(v => {
        this.itemData = v.data
      })
    }
  },
  mounted() {
    this.getToday()
    this.date = this.today

    this.getData()

    let toYear = new Date().getFullYear(),
    startYear = 2023
    while(startYear <= toYear) {
      this.specificMonthOptions.push({
        value: startYear, label: startYear, 
        children: this.monthEn.map(item => ({value: item, label: item}))
      })
      
      startYear++
    }
  }
}
</script>

<style scoped lang="scss">
.summary {
  width: 100%;
  .sr, .cs, .cp {
    margin-top: 2.5rem;
    color: #1B7379;
    font-weight: 700;

    .tip-title {
      margin-bottom: .9375rem;
      font-size: 1.5rem;
    }

    .card {
      width: 100%;
      padding: 1.25rem 1.25rem 0 1.25rem;
      box-sizing: border-box;
      border: 1px solid #1B7379;
      border-radius: 1.25rem;

      .single {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .description {
          width: 18.75rem;
          color: #000;
          font-size: 1.125rem;
          font-weight: 700;
        }

        .value {
          color: #000;
          font-size: 1.125rem;
          margin-left: .625rem;
          font-weight: 400;
        }
      }
    }
  }

  .cp .item {
    width: 100%;
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    color: #000;

    .label {
      margin-right: 1.25rem;
    }
  }
}
</style>
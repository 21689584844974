<template>
  <div>
    <div class="logo">
      <img :src="value" @click="updateFile" class="image" v-if="value" alt="">
      <div class="image" @click="updateFile" v-else>
        <img src="@/assets/images/item-close.png" class="icon" alt="">
      </div>
      <div class="logo-info">
        <span>Select a PNG or JPG image: </span>
        <span>at least 180px(H) x 180px(W), Cafe Dialogueum 1 MB</span>
        <el-button class="default" @click="isUpdateFile" v-if="value">Browse</el-button>
      </div>
    </div>
    
    <div class="browse" v-if="browse_switch && value" @click="browse_switch = false">
      <img :src="value" alt="">
    </div>
  </div>
</template>

<script>
import { file } from "@/api/files";
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      browse_switch: false,
      uploadUrl: '/files'
    }
  },
  methods: {
    isUpdateFile(){
      if(this.value==null){
         this.updateFile()
      }else {
        this.browse_switch = true
      }
    },
    // 上传文件
    updateFile() {
      let file = document.createElement('input')
      file.type = 'file'
      file.onchange = () => {
        let files = file.files[0],
        suffix = ['image/png', 'image/jpeg'],
        {size, type} = files,
        // 创建临时图片 获取图片宽高
        tempImg = URL.createObjectURL(files),
        img = new Image()
        img.src = tempImg

        img.onload = () =>  {
          let {width, height} = img
        
          if(!suffix.includes(type)) {
            this.$message.error('Please select PNG, JPG image')
          } else if(width < 180 || height < 180) {
            this.$message.error('at least 180px(H) x 180px(W)')
          } else if(1024 * 1024 < size) {
            this.$message.error('Maximum file size 1 MB')
          } else {
            // this.$emit('updateValue', tempImg)
            this.uploadFormData(file)
          }
          // isSize ? resolve() : reject();  
        }
        
        // setTimeout(() => {
        //   let {width, height} = img
          
        //   if(!suffix.includes(type)) {
        //     this.$message.error('Please select PNG, JPG image')
        //   } else if(width < 180 || height < 180) {
        //     this.$message.error('at least 180px(H) x 180px(W)')
        //   } else if(1024 * 1024 < size) {
        //     this.$message.error('Maximum file size 1 MB')
        //   } else {
        //     // this.$emit('updateValue', tempImg)
        //     this.uploadFormData(file)
        //   }
        // }, 1000);
      }

      file.click()
    },

    uploadFormData(dom) {
      const formData = new FormData();
      
      formData.append("file", dom.files[0]);
      file(this.uploadUrl, formData)
        .then((v) => {
          this.$emit('updateValue', v.data)
          this.$message({ type: "success", message: "上傳成功!"});
        })
        .finally(() => {
          dom.value = null;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  display: flex;
  align-items: center;

  .image {
    width: 11.25rem;
    height: 11.25rem;
    border-radius: .9375rem;
    border: 1px solid #6D6D6D;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    .icon {
      width: 2.5rem;
      height: auto;
      transform: rotateZ(45deg);
    }
  }

  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}

.browse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .4);

  img {
    max-height: 50%;
  }
}
</style>
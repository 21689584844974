<template>
  <div class="qr-codes">
    <div class="compulsory">
      <span class="label">*Compulsory</span>
      <span class="value">Last Published {{ beacons.updatedAt }}</span>
    </div>

    <el-form :model="beacons" status-icon :rules="rules" ref="ruleForm"
     label-width="260px" class="ruleForm" label-position="left">
      <div class="content">iBeacon Information</div>
      <el-form-item label="Location ID (Loc)" prop="loc">
        <el-input v-model.number="beacons.loc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="iBeacon UUID">
       <span class="value"> {{ beacons.uuid }}</span>
        <!-- <el-input v-model="beacons.uuid" autocomplete="off"></el-input> -->
      </el-form-item>
      <el-form-item label="iBeacon MAJOR Value">
        <span class="value"> {{ beacons.major }}</span>
        <!-- <el-input v-model.number="beacons.major" autocomplete="off"></el-input> -->
      </el-form-item>
      <el-form-item label="iBeacon MINOR Value" prop="minor">
        <!-- <span class="value"> {{ beacons.minor }}</span> -->
        <el-input v-model.number="beacons.minor" autocomplete="off"></el-input>
      </el-form-item>
      
    </el-form>
    <div class="btn" @click="save">Save</div>

    <div class="line"></div>

    <div class="header">
      <span>QR-Codes</span>
      <!-- <el-button class="default" @click="allDownload"><img src="@/assets/images/download.png" alt="">Download All</el-button> -->
    </div>

    <div class="qrcode-list">
      <div class="single">
        <div class="title">Plastic Free Actions</div>
        <div class="card">
          <div class="qrcode-single">
            <div class="qrcode-img tableware" ref="tableware">
              <!-- <img src="@/assets/images/qrcode.png" alt=""> -->
            </div>
            <span class="label">走餐具</span>
            <span class="stamp">+1 Stamps</span>
            <!-- <el-button class="default" @click="download('tableware')"><img src="@/assets/images/download.png" alt="">Dwonload</el-button> -->
          </div>
          <div class="qrcode-single">
            <div class="qrcode-img vessel" ref="vessel">
              <!-- <img src="@/assets/images/qrcode.png" alt=""> -->
            </div>
            <span class="label">走容器</span>
            <span class="stamp">+2 Stamps</span>
            <!-- <el-button class="default" @click="download('vessel')"><img src="@/assets/images/download.png" alt="">Dwonload</el-button> -->
          </div>
        </div>
      </div>
      <div class="single">
        <div class="title">Reward Redemption</div>
        <div class="card">
          <div class="qrcode-single">
            <div class="qrcode-img reward-1" ref="reward-1">
              <!-- <img src="@/assets/images/qrcode.png" alt=""> -->
            </div>
            <span class="label">Reward-1</span>
            <span class="stamp-red">-{{ detail.rewardRedemptions[0] && detail.rewardRedemptions[0].stampCostingNumber}} Stamps</span>
            <!-- <el-button class="default" @click="download('reward-1')"><img src="@/assets/images/download.png" alt="">Dwonload</el-button> -->
          </div>
          <div class="qrcode-single">
            <div class="qrcode-img reward-2" ref="reward-2">
              <!-- <img src="@/assets/images/qrcode.png" alt=""> -->
            </div>
            <span class="label">Reward-2</span>
            <span class="stamp-red">-{{detail.rewardRedemptions[1] && detail.rewardRedemptions[1].stampCostingNumber}} Stamps</span>
            <!-- <el-button class="default" @click="download('reward-2')"><img src="@/assets/images/download.png" alt="">Dwonload</el-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEateriesDetail } from '@/api/eatery'
import { editBeacons } from '@/api/device'
import QRCode from 'qrcodejs2'
// import qrcodeObj from '../qrcodeObj'
import { KJUR } from 'jsrsasign'

export default {
  props: {
    beacons: {
      type: Object
    }
  },
  data() {
    return {
      obj: null,
      detail: {
        rewardRedemptions: []
      },
      groups: [],
      rules: {
        uuid: [
          { required: true, message: 'Please Enter IBeacon UUID', trigger: 'blur' },
        ],
        major: [
          { required: true, message: 'Please Enter IBeacon MAJOR Value', trigger: 'blur' },
        ],
        minor: [
          { required: true, message: 'Please Enter IBeacon MINOR Value', trigger: 'blur' },
        ],
        loc: [
          { required: true, message: 'Please Enter Location ID (Loc)', trigger: 'blur' },
        ],
      }, 
    }
  }, 
  watch:{
    beacons:{
      handler(v){
       if(v!=null){
          this.createCode()
       }
      },
      deep:true
    }
  },
  created(){
    this.getEateriesDetail()
    // this.getBeacons()
  },
  methods: {
    save() {
      let form = {
        ...this.beacons
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editBeacons(form.id, form).then(() => {
            this.$message.success('Successfully Saved')
           this.createCode()
          })
        }
      })
    },
    allDownload() {
      let arr = ['tableware', 'vessel', 'reward-1', 'reward-2']
      for(let i = 0; i < arr.length; i++) this.download(arr[i])
    },
    // 下载
    download(type) {
      let aDom = document.createElement('a'),
      url = this.$refs[type].querySelector('img').src

      aDom.href = url
      aDom.setAttribute('download', `${type}.png`)
      aDom.click()
    },

    createCode() {
      let that= this
      let minor = this.beacons.minor
      let loc = this.beacons.loc
      console.log(this.beacons)
      let obj = {
        tableware: {"act": '11', "min": minor, "loc": loc},
        vessel: {"act": '12', "min": minor, "loc": loc},
        'reward-1': {"act": '51', "min": minor, "loc": loc},
        'reward-2': {"act": '52', "min": minor, "loc": loc},
        // 'reward-2': {"act":"52","min":869,"loc":"18699"}
      }

      let qrcode = {}
      for(let key in obj) {
        console.log(key)
        qrcode[key] = this.getJwt(obj[key])
      }
      
      setTimeout(() => {
        for(let key in qrcode) {
          new QRCode(that.$refs[key], {
            text: qrcode[key],
            // text: JSON.stringify(obj[key]),
            width: 140,
            height: 140,
            // correctLevel: QRCode.CorrectLevel.H
          })
        }
      }, 2000)
      
    },

    getEateriesDetail(){
      getEateriesDetail(this.$route.params.id).then((v)=>{
        this.detail = v.data
        this.detail.rewardRedemptions.sort((cur, next) => 
          cur.type - next.type)
      })
    },

    getJwt(oPayload) {
      var oHeader = {alg: 'HS256', typ: 'JWT'};
      var sHeader = JSON.stringify(oHeader);
      var sPayload = JSON.stringify(oPayload);
      return KJUR.jws.JWS.sign("HS256", sHeader, sPayload, "616161");
    }
  },
  mounted() {
    // console.log(createLocalJWKSet)
    this.createCode()
  }
}
</script>

<style scoped lang="scss">
.qr-codes {
  width: 100%;

  .compulsory { 
    font-size: 1.125rem;
    color:#1B7379;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.1875rem;
    margin-top: 1.875rem;
  }
  .ruleForm {
    margin-top: 1.875rem;
    width: 67%;

    .value {
      font-size: 1.125rem;
    }

  }
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  .btn {
    width: 17.5rem;
    height: 3.75rem;
    background-color: #1B7379;
    border-radius: 100px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 1.875rem;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: #F0F0EF;
    margin: 3.125rem 0;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    color: #1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
    
    span {
      margin-right: .9375rem;
    }
  }

  .qrcode-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;

    .single {
      width: 30rem;
      height: 28.125rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-right: 1.875rem;
      .title {
        color: #1B7379;
        font-weight: 700;
        font-size: 1.125rem;
        margin-bottom: .9375rem;
      }
      
      .card {
        width: 100%;
        flex: 1;
        background-color: #FAFAFA;
        border-radius: 1.25rem;
        padding: 1.25rem;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .qrcode-single {
          width: 11.25rem;
          .qrcode-img {
            width: 11.25rem;
            height: 11.25rem;
            // height: auto;
            margin-bottom: .625rem;
            border: 1px solid #6D6D6D;
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            box-sizing: border-box;

            & > img {
              width: 100%;
              height: 100%;
            }
          }

          .label {
            display: block;
            width: 100%;
            color: #000;
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: .625rem;
          }

          .stamp, .stamp-red {
            display: block;
            width: 100%;
            color: #1B7379;
            font-size: 1.125rem;
            margin-bottom: .625rem;
          }

          .stamp-red {
            color: #C82C2C;
          }
        }
      }
    }
  }
} 
</style>
import http from '@/utils/http'

export function getEateriesGroup(params){
  return http('/eatery_groups', {params})
}

export function addEateriesGroup(data){
  return http.post('/eatery_groups', data)
}

export function getEateriesGroupDetail(id){
  return http(`/eatery_groups/${id}`)
}

export function editEateriesGroupDetail(id, data){
  return http.put(`/eatery_groups/${id}`, data)
}

export function delEateriesGroup(id){
  return http.delete(`/eatery_groups/${id}`)
}

export function getEateriesGroupSummary(params){
  return http(`/eatery_group_summary`, {params})
}

export function bulkImportEateriesGroup(data){
  return http.post(`/bulk_import_eatery_groups`, data)
}
<template>
  <div class="profile">
    <el-form :model="ruleForm" status-icon ref="ruleForm"
     label-width="260px" class="ruleForm" label-position="left">
      <div class="content">Basic Information</div>
      <el-form-item label="Eatery ID">
        <span class="value">{{ ruleForm.number }}</span>
      </el-form-item>
      <el-form-item label="Eatery Name (TC)" prop="eaterName_tc">
        <span class="value">{{ ruleForm.eaterName_tc }}</span>
      </el-form-item>
      <el-form-item label="Eatery Name (EN)" prop="eaterName_en">
        <span class="value">{{ ruleForm.eaterName_en }}</span>
      </el-form-item>
      <el-form-item label="Logo">
        <img :src="ruleForm.logo" alt="" class="logo">
      </el-form-item>
      <el-form-item label="Eatery Group">
        <span class="value">{{ ruleForm.eateryGroup }}</span>
      </el-form-item>
      <el-form-item label="Catering Type" prop="cateringType">
        <div class="value">{{ ruleForm.cateringType }}</div>
        <div class="value" v-if="ruleForm.is_bring_your_own_carrier">BYOC (Bring Your Own Carrier)</div>
      </el-form-item>

      <div class="content">Location</div>
      <el-form-item label="District" prop="district">
        <span class="value">{{ ruleForm.district }}</span>
      </el-form-item>
      <el-form-item label="Area" prop="area">
        <span class="value">{{ ruleForm.area }}</span>
      </el-form-item>
      <el-form-item label="Address (TC)" prop="address_tc">
        <span class="value">{{ ruleForm.address_tc }}</span>
      </el-form-item>
      <el-form-item label="Address (EN)" prop="address_en">
        <span class="value">{{ ruleForm.address_en }}</span>
      </el-form-item>
      <el-form-item label="Map URL" prop="mapURL">
        <span class="value">{{ ruleForm.mapURL }}</span>
      </el-form-item>

      <div class="content">Contact Information</div>
      <el-form-item label="Contact Person">
        <span class="value">{{ ruleForm.contactPerson }}</span>
      </el-form-item>
      <el-form-item label="Contact No.">
        <span class="value">{{ ruleForm.contactNo }}</span>
      </el-form-item>
      <el-form-item label="Contact Email address">
        <span class="value">{{ ruleForm.email }}</span>
      </el-form-item>

      <div class="content">Login Information</div>
      <el-form-item label="Username" prop="username">
        <div class="value">{{ ruleForm.username }}</div>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <!-- {{ ruleForm.password }} -->
        <div class="value">****************</div>
      </el-form-item>

      <div class="content">General T&C</div>
      <el-form-item label="Traditional Chinese" prop="general_tc">
        <span class="value">{{ ruleForm.general_tc }}</span>
      </el-form-item>
      <el-form-item label="English" prop="general_en">
        <span class="value">{{ ruleForm.general_en }}</span>
      </el-form-item>
    </el-form>
    
  </div>
</template>

<script>
import { getEateriesGroup } from '@/api/eateryGroup'

export default {
  props: {
    type: {
      type: String,
    },
    ruleForm: {
      type: Object
    } 
  },
  data() {
    return {

      rules: {
        eaterName_tc: [
          { required: true, message: 'Please Enter Eatery Name (TC)', trigger: 'blur' },
        ],
        eaterName_en: [
          { required: true, message: 'Please Enter Eatery Name (EN)', trigger: 'blur' },
        ],
        cateringType: [
          { required: true, message: 'Please Enter Catering Type', trigger: 'blur' },
        ],
        district: [
          { required: true, message: 'Please Enter District', trigger: 'blur' },
        ],
        area: [
          { required: true, message: 'Please Enter Area', trigger: 'blur' },
        ],
        address_tc: [
          { required: true, message: 'Please Enter Address (TC)', trigger: 'blur' },
        ],
        address_en: [
          { required: true, message: 'Please Enter Address (EN)', trigger: 'blur' },
        ],
        mapURL: [
          { required: true, message: 'Please Enter MapURL', trigger: 'blur' },
        ],
        general_tc: [
          { required: true, message: 'Please Enter Traditional Chinese', trigger: 'blur' },
        ],
        general_en: [
          { required: true, message: 'Please Enter English', trigger: 'blur' },
        ],
      },

    }
  }, 
  methods: {
    getEateryGroups() {
      let form = {
        like_eatery_group_id_or_name: '',
        name: '',
        _start: 0,
        _limit: 10000,
      }

      this.groups = []
      getEateriesGroup(form).then(v => {
        v.data.filter(its => this.ruleForm.eateryGroup == its.id && (this.ruleForm.eateryGroup = its.name.zhHant))
      })
    },
  },
  mounted() {
    this.getEateryGroups()
  }
}
</script>

<style scoped lang="scss">
.profile {
  width: 100%;

  .compulsory { 
    font-size: 1.125rem;
    color:#1B7379;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.1875rem;
    margin-top: 1.875rem;
  }
  .ruleForm {
    margin-top: 1.875rem;
    width: 67%;

    .value {
      font-size: 1.125rem;
      line-height: 3.125rem;
    }

    .logo {
      width: 11.25rem;
      height: 11.25rem;
      border-radius: .9375rem;
      border: 1px solid #6D6D6D;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F9F9F9;
      .icon {
        width: 2.5rem;
        height: auto;
        transform: rotateZ(45deg);
      }
    }

  }
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
  }

  .table-box{
    width:100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .editor {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }
} 
</style>
<template>
  <div class="eatery-edit">
    <!-- 头部功能栏 -->
    <Module-Header :title="ruleForm.eaterName_tc" :leading="adminUserInfo.position == 3 ? false : true">
      <el-button class="search" @click="toTransaction"><img src="@/assets/images/operation.png" />Transaction</el-button>
      <el-button class="default" @click="disable" v-if="adminUserInfo.position == 1 && ruleForm.status == 0">Disable</el-button>
      <el-button class="delete" @click="enable" v-if="adminUserInfo.position == 1 && ruleForm.status == 1">Enable</el-button>
      <el-button class="delete" @click="del" v-if="adminUserInfo.position == 1"><img src="@/assets/images/delete-01.png" />Delete</el-button>
    </Module-Header>

    <!-- tab栏 -->
    <div class="tab-nav">
      <div :class="['nav', item.select && 'select-nav']" :style="`--proportion: ${100 / handleTabNav.length}%`" @click="clickNav(idx)"
        v-for="(item, idx) in handleTabNav" :key="idx">
        <span>{{ item.itemName }}</span>
        <span class="tag" v-if="!item.select && item.tag > 0">{{ item.tag }}</span>
      </div>
    </div>

    <!-- 组件栏 -->
    <div class="swiper-item" v-if="eateryCate == 0"> 
      <Profile ref="Profile" type="edit" v-if="ruleForm.id && adminUserInfo.position == 1" :form="ruleForm" @save="save"></Profile> 
      <ProfileDetail v-else-if="ruleForm.id" :ruleForm="ruleForm"></ProfileDetail>
    </div>
    <div class="swiper-item" v-if="eateryCate == 1">
      <Offers @tabOffersEdit="tabOffersEdit" v-if="adminUserInfo.position == 1"></Offers>
      <OffersDetail v-else></OffersDetail>
    </div>
    <!-- <div class="swiper-item" v-if="adminUserInfo.position == 1 && eateryCate == 2"> 
      <Pending :pending="pending" @updateData="tabOffers" v-if="adminUserInfo.position == 1 && pending"></Pending>
      <PendingEatery v-else></PendingEatery>
    </div> -->
    <div class="swiper-item" v-if="adminUserInfo.position == 1 && eateryCate == 2"> <QRCodesr v-if="beacons.id" :beacons="beacons"></QRCodesr> </div>
    <div class="swiper-item" v-if="eateryCate == (adminUserInfo.position == 1 ? 3 : 2)"> <Summary></Summary> </div>
    
  </div>
</template>
<script>
import Profile from './components/profile/profile.vue';
import ProfileDetail from './components/profile/profile_detail.vue';
import Offers from './components/offers/offers.vue';
import OffersDetail from './components/offers/offers_detail.vue';
// import Pending from './components/pending/pending.vue'
// import PendingEatery from './components/pending/pending_Eatery.vue'
import QRCodesr from './components/qrCodes/qrCodes.vue'
import Summary from './components/summary/summary.vue';
import { editEateriesDetail, getEateriesDetail, disableEateries, enableEateries, delEateries } from '@/api/eatery'

export default {
  components: {
    Profile,
    ProfileDetail, 
    Offers,
    OffersDetail,
    // Pending,
    // PendingEatery,
    QRCodesr,
    Summary,
  },
  data() {
    return {
      eateryCate: 0,
      ruleForm: {
        typeZou: [],
      },

      offers: null,
      pending: null,
      offersDetail: {
        show: false,
        data: {}
      },
      beacons: {}
    };
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
    handleTabNav: function() {
      let tabNav = []
      if(this.adminUserInfo.position == 1) {
        tabNav = [
          {itemName: 'Profile', select: true, tag: 0},
          {itemName: 'Offers', select: false, tag: 0},
          // {itemName: 'Offer Approval', select: false, tag: 0},
          {itemName: 'QR Codes & iBeacon', select: false, tag: 0},
          {itemName: 'Summary', select: false, tag: 0},
        ]
      } else {
        tabNav = [
          {itemName: 'Profile', select: true, tag: 0},
          {itemName: 'Offers', select: false, tag: 0},
          {itemName: 'Summary', select: false, tag: 0},
        ]
      }

      return tabNav
    }
  },
  methods: {
    toTransaction() {
      let keysWord = this.ruleForm.id
      this.$router.push({path: '/stampTransactions', query: {keysWord, id: 'eatery_id'}})
    },
    disable() {
      let { id } = this.$route.params
      disableEateries(id).then(() => {
        this.ruleForm.status = 1
      })
    },
    enable() {
      let { id } = this.$route.params
      enableEateries(id).then(() => {
        this.ruleForm.status = 0
      })
    },
    del() {
      let { id } = this.$route.params
      this.$popUp.config({
        content: 'Please confirm whether to Delete',
        confirm: {
          e: () => {
            delEateries(id).then(() => {
              this.$popUp.removePopup()
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clickNav(idx) {
      this.eateryCate = idx
      this.offersDetail.show && (this.offersDetail.show = false)
      this.handleTabNav.filter((its, index) => index == idx ? its.select = true : its.select = false) 
    },
    tabOffers() {
      this.offersDetail.show = false
    },
    tabOffersEdit(row) {
      this.offersDetail.show = true
      this.offersDetail.data = row
    },
    save(ruleForm) {
      let { id } = this.$route.params
      let profile = this.$refs.Profile,
      obj = {
        "name": {
          "zh_hant": ruleForm.eaterName_tc,
          "en": ruleForm.eaterName_en,
        },
        "logo": ruleForm.logo,
        // "eatery_group": {
        //   "id": ruleForm.eateryGroup
        // },
        "catering_type": {
          "zh_hant": ruleForm.cateringType.split('/')[1],
          "en": ruleForm.cateringType.split('/')[0]
        },
        "is_bring_your_own_carrier": ruleForm.is_bring_your_own_carrier,
        "location":{
          "district": {
            "zh_hant": ruleForm.district.split('/')[1],
            "en": ruleForm.district.split('/')[0]
          },
          "area": {
            "zh_hant": ruleForm.area.split('/')[1],
            "en": ruleForm.area.split('/')[0]
          },
          "address":{
            "zh_hant": ruleForm.address_tc,
            "en": ruleForm.address_en
          },
          "map_url": ruleForm.mapURL
        },
        "contact": {
          "name": ruleForm.contactPerson,
          "phone_number": ruleForm.contactNo,
          "email_address": ruleForm.email
        },
        "general": {
          "zh_hant": ruleForm.general_tc,
          "en": ruleForm.general_en
        },
        support_campaign_type: ruleForm.support_campaign_type,
        "user": {
          "username": ruleForm.username,
          "password": ruleForm.password
        }
      }

      profile.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          editEateriesDetail(id,obj).then(() => {
            this.$message.success('Successfully Saved')
            this.$router.go(-1)
          })
        }
      })
    },
    getData() {
      let { id } = this.$route.params
      getEateriesDetail(id).then((v) => {
        let data = v.data,
        pending = [{}, {}]
        
        this.ruleForm = {
          number: data.number,
          id: data.id,
          eaterName_tc: data.name.zhHant,
          eaterName_en: data.name.en,
          logo: data.logo,
          eateryGroupName: data.eateryGroup ? data.eateryGroup.name.zhHant : '',
          eateryGroup: data.eateryGroup ? data.eateryGroup.id : '',
          cateringType: data.cateringType.en + '/' + data.cateringType.zhHant,
          is_bring_your_own_carrier: data.isBringYourOwnCarrier, 
          district: data.location.district.en + '/' + data.location.district.zhHant,
          area: data.location.area.en + '/' + data.location.area.zhHant,
          address_tc: data.location.address.zhHant,
          address_en: data.location.address.en,
          mapURL: data.location.mapUrl,
          contactPerson: data.contact.name,
          contactNo: data.contact.phoneNumber,
          email: data.contact.emailAddress,
          general_tc: data.general.zhHant,
          general_en: data.general.en,
          published_at: new Date(data.publishedAt).format('yyyy/MM/dd hh:mm:ss'),
          status: data.status,
          support_campaign_type: data.supportCampaignType,
          username: data.user.email,
          password: data.user.password,
        }
        data.beacon.updatedAt = new Date(data.beacon.updatedAt).format('yyyy/MM/dd hh:mm:ss')
        this.beacons = data.beacon
        // this.handleTabNav[2].tag = 0

        data.rewardRedemptions.filter((its, index) => {
          its.name_tc = its.item.zhHant
          its.name_en = its.item.en
          its.general_tc = its.general.zhHant
          its.general_en = its.general.en
          its.general_tc_select = false
          its.general_en_select = false
          its.is_disable = its.is_disable ? true : false
          its.curIdx = index
          if(its.status == 0) {
            if(its.type == 0) pending[0] = its
            else if(its.type == 1) pending[1] = its
            // this.handleTabNav[2].tag++
          } else {
            if(its.type == 0) pending[0] = {}
            else if(its.type == 1) pending[1] = {}
          }
          // its.date = `${its.startDate.substr(0, 10)} - ${its.endDate.substr(0, 10)}`
          
        })
        this.pending = pending

      })
    },
  },
  created(){
    // this.getReward()
    this.getData()
  },
  mounted(){

  },
};
</script>

<style lang="scss" scoped>
.eatery-edit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;

  .tab-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.875rem;
    
    .nav {
      width: calc(var(--proportion) - .5rem);
      padding: .625rem;
      text-align: center;
      border-bottom: 3px solid #F0F0EF;
      font-size: 22px;
      font-weight: 700;
      color: #6D6D6D;
      cursor: pointer;
      position: relative;
      .tag {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: #C82C2C;
        color: #fff;
        font-size: 1.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .select-nav {
      border-bottom-color: #1B7379;
      color: #1B7379;
    }
  }

  .swiper-item {
    width: 100%;
  }
}
</style>
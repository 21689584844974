<template>
  <div class="profile">
    <div class="compulsory" v-if="type == 'edit'">
      <span class="label">*Compulsory</span>
      <span class="value">Last Published {{ ruleForm.published_at }}</span>
    </div>

    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
     label-width="260px" class="ruleForm" label-position="left">
      <div class="content">
        <span>Basic Information</span>
      </div>
      <el-form-item label="Eatery ID" v-if="type == 'edit'">
        <span class="value">{{ ruleForm.number }}</span>
      </el-form-item>
      <el-form-item label="Eatery Name (TC)" prop="eaterName_tc">
        <el-input v-model="ruleForm.eaterName_tc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Eatery Name (EN)" prop="eaterName_en">
        <el-input v-model="ruleForm.eaterName_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Logo">
        <Upload :value="ruleForm.logo" @updateValue="v => ruleForm.logo = v"></Upload>
      </el-form-item>
      <el-form-item label="Eatery Group">
        <span class="value" v-if="type == 'edit'">{{ ruleForm.eateryGroupName }}</span>
        <el-select v-else v-model="ruleForm.eateryGroup" clearable style="width: 100%">
          <el-option
            v-for="(item,index) in groups" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Catering Type" prop="cateringType">
        <el-select v-model="ruleForm.cateringType" clearable style="width: 100%">
          <el-option
            v-for="(item,index) in CateringType" :key="index" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-checkbox v-model="ruleForm.is_bring_your_own_carrier" text-color="#1B7379">BYOC (Bring Your Own Carrier)</el-checkbox>
      </el-form-item>
      
      <el-form-item prop="support_campaign_type">
        <el-checkbox-group v-model="typeZou" @change="changeTypeZou">
          <el-checkbox :label="1">走即棄餐具</el-checkbox>
          <el-checkbox :label="2">走即棄容器</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <div class="content">Location</div>
      <el-form-item label="District" prop="district">
        <el-select v-model="ruleForm.district" clearable style="width: 100%">
          <el-option
            v-for="(item,index) in District" :key="index" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Area" prop="area" v-if="area">
        <el-select v-model="ruleForm.area" clearable style="width: 100%">
          <el-option
            v-for="(item,index) in area" :key="index" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Address (TC)" prop="address_tc">
        <el-input v-model="ruleForm.address_tc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Address (EN)" prop="address_en">
        <el-input v-model="ruleForm.address_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Map URL" prop="mapURL">
        <el-input v-model="ruleForm.mapURL" autocomplete="off"></el-input>
      </el-form-item>

      <div class="content">Contact Information</div>
      <el-form-item label="Contact Person">
        <el-input v-model="ruleForm.contactPerson" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Contact No.">
        <el-input v-model="ruleForm.contactNo" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Contact Email address">
        <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
      </el-form-item>

      <div class="content">Login Information</div>
      <el-form-item label="Username" prop='username'>
        <el-input v-model="ruleForm.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop='password'>
        <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
      </el-form-item>

      <div class="content">General T&C</div>
      <el-form-item label="Traditional Chinese">
        <el-input type="textarea" v-model="ruleForm.general_tc" autocomplete="off" placeholder="Please input here..."></el-input>
      </el-form-item>
      <el-form-item label="English">
        <el-input type="textarea" v-model="ruleForm.general_en" autocomplete="off" placeholder="Please input here..."></el-input>
      </el-form-item>
    </el-form>

    <el-button @click="save" v-if="type == 'edit'">Save & Publish</el-button>
  </div>
</template>

<script>
import Upload from '@/components/upload'
import { getSetting } from '@/api/setting'
import { getEateriesGroup } from '@/api/eateryGroup'
import { District } from './profile'

export default {
  props: {
    type: {
      type: String,
    },
    form: {
      type: Object
    }
  },
  components: { Upload },
  data() {
    return {
      groups: [],
      District, 
      CateringType: [],
      typeZou: [],
      ruleForm: {},

      rules: {
        eaterName_tc: [
          { required: true, message: 'Please Enter Eatery Name (TC)', trigger: 'blur' },
        ],
        eaterName_en: [
          { required: true, message: 'Please Enter Eatery Name (EN)', trigger: 'blur' },
        ],
        logo: [
          { required: true, message: 'Please select Logo', trigger: 'blur' },
        ],
        cateringType: [
          { required: true, message: 'Please Enter Catering Type', trigger: 'blur' },
        ],
        support_campaign_type: [
          { required: true, message: 'Select At Least One Item', trigger: 'blur' },
        ],
        district: [
          { required: true, message: 'Please Enter District', trigger: 'blur' },
        ],
        area: [
          { required: true, message: 'Please Enter Area', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Please Enter Contact Email address', trigger: 'blur' },
          { type: 'email', message: 'Please Enter The Correct Email address', trigger: ['blur', 'change'] }
        ],
        username: [
          { required: true, message: 'Please Enter Username', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Please Enter Password', trigger: 'blur' },
        ],
        address_tc: [
          { required: true, message: 'Please Enter Address (TC)', trigger: 'blur' },
        ],
        address_en: [
          { required: true, message: 'Please Enter Address (EN)', trigger: 'blur' },
        ],
        mapURL: [
          { required: true, message: 'Please Enter MapURL', trigger: 'blur' },
        ],
        general_tc: [
          { required: true, message: 'Please Enter Traditional Chinese', trigger: 'blur' },
        ],
        general_en: [
          { required: true, message: 'Please Enter English', trigger: 'blur' },
        ],
      },

    }
  }, 
  watch: {
    area: function(val) {
      if(val && !this.ruleForm.area) this.ruleForm.area = val[0]
    }
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
    area: function() {
      let [district] = this.District.filter(its => its.label == this.ruleForm.district)
      
      return district ? district.children : null
    }
  },
  methods: {
    save() {
      let { ruleForm } = this
      this.$emit('save', ruleForm)
    },
    changeTypeZou(v) {
      if(v.length == 0) {
        this.ruleForm.support_campaign_type = ''
      } else if(v.length == 2) {
        this.ruleForm.support_campaign_type = 0
      } else {
        this.ruleForm.support_campaign_type = v[0]
      }
    },
    getCateringType() {
      getSetting().then(v => {
        this.district = v.data.suggestedSearchLabels.district.slice(0, 4)
        this.area = v.data.suggestedSearchLabels.district.slice(4)
        this.cateringType = v.data.suggestedSearchLabels.cateringType
      })
    },
    getEateryGroups() {
      let form = {
        like_eatery_group_id_or_name: '',
        name: '',
        _start: 0,
        _limit: 10000,
      }

      this.groups = []
      getEateriesGroup(form).then(v => {
        v.data.filter(its => this.groups.push({name: its.name.zhHant, id: its.id}))
      })
    },
    findSetting() {
      getSetting().then((v) => {
        v.data.cutleryCouponStock.editHistories.filter(its => {
          its.date = new Date(its.datetime).format('yyyy/MM/dd hh:mm:ss')
          its.change = its.adjustedStock - its.originalStock 
        })
        this.CateringType = v.data.suggestedSearchLabels.cateringType.map(its => `${its.en}/${its.zhHant}`)
      })
    }
  },
  mounted() {
    // console.log(this.form)
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
    switch(this.ruleForm.support_campaign_type) {
      case '': this.typeZou = [] ;break
      case 0: this.typeZou = [1, 2] ;break
      case 1: this.typeZou = [1] ;break
      case 2: this.typeZou = [2] ;break
    }

    // this.getCateringType()
    this.findSetting()
    this.getEateryGroups()
  }
}
</script>

<style scoped lang="scss">
.profile {
  width: 100%;

  .compulsory { 
    font-size: 1.125rem;
    color:#1B7379;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.1875rem;
    margin-top: 1.875rem;
  }
  .ruleForm {
    margin-top: 1.875rem;
    width: 100%;

    & > * {
      width: 67%;
    }
    .value {
      font-size: 1.125rem;
    }

    .logo {
      width: 100%;
      display: flex;
      align-items: center;

      .image {
        width: 11.25rem;
        height: 11.25rem;
        border-radius: .9375rem;
        border: 1px solid #6D6D6D;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F9F9F9;
        .icon {
          width: 2.5rem;
          height: auto;
          transform: rotateZ(45deg);
        }
      }

      .logo-info {
        flex: 1;
        padding-left: 1.25rem;
        color: #000;
        font-size: 1.125rem;
        line-height: 1.25rem;

        span {
          display: block;
          margin-bottom: .625rem;
        }
      }
    }

  }
  .content {
    width: 100%;
    color:#1B7379;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .change-pedding {
      margin-right: 1.25rem;
      color: #1B7379;
      font-weight: 700;
      font-size: 1.125rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: -2rem;
        top: 0;
        width: 1.125rem;
        height: 1.125rem;
        background-color: #C82C2C;
        border-radius: 50%;
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .table-box{
    width:100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .editor {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }
} 
</style>
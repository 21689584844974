<template>
  <div class="offers-detail">
    <div class="header">Offer </div>

    <div class="container" v-for="(its, idx) in ruleForm" :key="idx">
      <div class="current" :style="`--bgcolor: #1B7379; --title: 'Offer ${idx + 1}'; --textcolor: #fff`">
        <div class="btn">
          <el-button :class="[!its.isDisable ? 'search-min' : 'white-min']" @click="disabled(idx)">Enable</el-button>
          <el-button :class="[its.isDisable ? 'delete-min' : 'white-min']" @click="disabled(idx)">Disable</el-button>
        </div>
        <el-form :model="its" status-icon ref="ruleForm"
          label-width="260px" class="ruleForm" label-position="top">
          <el-form-item label="Item (TC)">
            <el-input v-model="its.name_tc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Item (EN)">
            <el-input v-model="its.name_en" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Number of Stamps Costing">
            <div class="handler-data">
              {{ its.stampCostingNumber }}
            </div>
          </el-form-item>
          <el-form-item label="T&C (TC)">
            <el-input type="textarea" v-model="its.general_tc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="T&C (EN)">
            <el-input type="textarea" v-model="its.general_en" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>

        <div class="operation">
          <div class=""></div>
          <div class="update">
            <!-- <span @click="reset">Reset</span> -->
            <el-button class="default" @click="update(idx)" v-if="!its.id" :disabled="btnDisable">Add</el-button>
            <el-button class="default" @click="update(idx)" v-else>Update</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { editRewardRedemptions, getRewardRedemptions, addRewardRedemptions } from '@/api/reward'
export default {
  // props: {
  //   detail: {
  //     type: Object,
  //   }
  // },
  data() {
    return {
      dates: [],
      ruleForm: {},
      disable: true,

      offer1: {
        item_tc: '',
        item_en: '',
        general_tc: '',
        general_en: '',
        general_tc_select: false,
        general_en_select: false,
        stampCostingNumber: 6,
        status: 0,
        isDisable: true,
      }, 
      offer2: {
        item_tc: '',
        item_en: '',
        general_tc: '',
        general_en: '',
        general_tc_select: false,
        general_en_select: false,
        stampCostingNumber: 12,
        status: 0,
        isDisable: true,
      }, 

      btnDisable: false
    }
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  methods: {
    handleDate(v) {
      let [start, end] = v
      this.ruleForm.date = new Date(start).format('yyyy-MM-dd') + ' - ' + new Date(end).format('yyyy-MM-dd')
    },
    disabled(idx) {
      this.ruleForm[idx].isDisable = !this.ruleForm[idx].isDisable
      // this.disable = !this.disable
      console.log(this.ruleForm )
    },

    reset() {
      // this.ruleForm = JSON.parse(JSON.stringify(this.detail))
    },
    update(idx) {
      let ruleForm = this.ruleForm[idx],
      form = {
        item: {
          zh_hant: ruleForm.name_tc,
          en: ruleForm.name_en,
        },
        general: {
          zh_hant: ruleForm.general_tc,
          en: ruleForm.general_en,
        },
        is_disable: ruleForm.isDisable
      }

      // 修改
      if(ruleForm.id) {
        editRewardRedemptions(ruleForm.id, form).then(() => {
          this.$message.success('修改成功！')
        })
      }
      // 新增
      else {
        form.eatery_id = this.$route.params.id
        form.stamp_costing_number = idx == 0 ? 6 : 12
        form.type = idx
        this.btnDisable = true
        addRewardRedemptions(form).then(() => {
          this.$message.success('新增成功！')
          this.getData()
          
        })
      }
      
    },
    getData() {
      let eatery_id = this.$route.params.id
      this.btnDisable = false
      
      getRewardRedemptions({eatery_id}).then(v => {
        console.log(v.data)
        let offers = [this.offer1, this.offer2]
        v.data.filter((its, index) => {
          its.name_tc = its.item.zhHant
          its.name_en = its.item.en
          its.general_tc = its.general.zhHant
          its.general_en = its.general.en
          its.general_tc_select = false
          its.general_en_select = false
          its.is_disable = its.is_disable ? true : false
          its.curIdx = index
          // if(its.status == 0) {
          //   if(its.type == 0) offers[0] = this.offer1
          //   else if(its.type == 1) offers[1] = this.offer2
          // } else {
            
          // }
          if(its.type == 0) offers[0] = its
          else if(its.type == 1) offers[1] = its
        })
        this.ruleForm = offers
        console.log(this.ruleForm)
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.offers-detail {
  width: 100%;
  margin-top: 1.25rem;
  height: fit-content;
  border-radius: 1.25rem;
  background-color: #FAFAFA;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .header {
    width: 100%;
    height: 4.25rem;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: #1B7379;
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .container {
    // width: 100%;
    padding: 1.875rem 1.25rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .current {
      width: 37.5rem;
      border-radius: .9375rem;
      border: 1px solid #BCBCBC;
      padding: 1.25rem 1.5625rem 1.875rem 1.5625rem;
      box-sizing: border-box;
      position: relative;
      height: fit-content;
      .btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.25rem;
      }

      &:after {
        content: var(--title, 'Current');
        position: absolute;
        top: -1.5rem;
        left: 1.25rem;
        height: 3rem;
        padding: 0 1.25rem;
        background-color: var(--bgcolor, #F0F0EF);
        border-radius: .9375rem;
        line-height: 3rem;
        color: var(--textcolor, #000);
        font-size: 1.125rem;
        font-weight: 700;
      }

      .handler-data {
        display: flex;
        align-items: center;

        img {
          width: 2.125rem;
          height: auto;
          cursor: pointer;
        }

        .ipt {
          width: 6.25rem;
          margin: 0 .625rem;
        }
      }

      .date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #000;
        font-size: 1.125rem;

        img {
          width: 1.875rem;
          height: auto;
        }
      }

      .operation {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.25rem;

        .update {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            color: #1B7379;
            font-weight: 700;
            font-size: 1.125rem;
            margin-right: 2.5rem;
            cursor: pointer;
          }
        }
      }
      .card-single {
        width: 100%;
        margin-top: 1.25rem;
        color: #000;
        font-size: 1.125rem;
        .label {
          font-weight: 700;
          margin-bottom: .625rem;
        }
        .expand {
          width: 100%;
          border-bottom: 1px solid #1B7379;
          padding-bottom: .625rem;
          .t-c {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .625rem 0;
            box-sizing: border-box;
            
            img {
              width: 1rem;
              height: auto;
            }
          }
          .description {
            width: 100%;
            font-size: .875rem;
            font-weight: 400;
          }
        }
      }
    }
  }

}
</style>